<template>
  <Layout :title="$t('segmentation.title')">
    <div :class="prefixCls">
      <HaiUpload @onChange="onSubmitFile" :maxSize="10"></HaiUpload>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import HaiUpload from '@/components/HaiUpload'
import { GetSegmentation, GetSegmentationResult } from '../api/create'
const prefixCls = 'segmentation-page'
export default {
  name: 'Segmentation',
  components: {
    Layout,
    HaiUpload
  },

  data() {
    return {
      currentRes: 0,
      prefixCls,
      filename: ''
    }
  },

  methods: {
    // 提交文件
    async onSubmitFile(file) {
      const formData = new FormData()
      this.filename = file.name
      formData.append('files', file)
      formData.append('data', file.name)
      this.currentRes = await this.$Loading.startTo(0, 15, this.$t('wait'))
      try {
        const response = await GetSegmentation(formData)
        if (response.status === 200) {
          await this.getFetchResult(response.mp3_uuid)
        } else {
          this.toNextPage()
        }
      } catch (e) {
        this.toNextPage()
      }
    },

    async getFetchResult(mp3_uuid) {
      const response = await GetSegmentationResult({ mp3_uuid })
      if (response.status === 200 && response.progress < 100) {
        if (this.currentRes > response.progress) {
          setTimeout(() => {
            this.getFetchResult(mp3_uuid)
          }, 2000)
        } else {
          if (this.currentRes > response.progress) {
            response.progress = this.currentRes
          }
          this.currentRes = await this.$Loading.startTo(
            this.currentRes,
            +response.progress,
            response.result_dict.msg[this.$i18n.locale]
          )
          await this.getFetchResult(mp3_uuid)
        }
      } else {
        this.toNextPage(response)
      }
    },

    toNextPage(data) {
      window.sessionStorage.setItem('segmentation_url', JSON.stringify(data))
      window.sessionStorage.setItem(
        'upload_filename',
        JSON.stringify(this.filename)
      )
      this.$router.push({ name: 'segmentationResult' })
      this.$Loading.finish()
    }
  }
}
</script>

<style lang="scss">
$prefixCls: 'segmentation-page';
.#{$prefixCls} {
  padding-top: 180px;
  height: 100%;
}
</style>
